import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { InterviewStatusEnum, getContactDisplayNames } from "app-types";
import { formatDistanceToNow } from "date-fns";
import { FC, useMemo } from "react";
import {
  InfoTooltip,
  Pill,
  PillColorsEnum,
  SizesEnum,
  TableSkeleton,
  toHumanReadableString,
} from "ui";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { AssessmentScore } from "../insights/assessmentScore";
import { SentimentScore } from "../insights/sentimentScore";
import { PaginationControls } from "../miscellaneous/paginationControls";
import { InterviewQuoteMatch } from "./interviewQuoteMatch";
import { InterviewSummary } from "./interviewSlideover/interviewSummary";
import {
  fetchNonEmptyInterviewsForProject,
  selectInterviewIdToMatchingFragmentId,
  selectInterviewParameters,
  selectTotalInterviewPages,
} from "./interviewsSlice";

export interface InterviewsTableProps {
  interviews: InterviewWithContactAndActivities[];
  isLoading: boolean;
  onInterviewClick?: (
    interviewId: string,
    matchingTranscriptFragmentId?: string
  ) => void;
  isRecruitingProject: boolean;
}

export const InterviewsTable: FC<InterviewsTableProps> = (props) => {
  const { interviews, onInterviewClick, isRecruitingProject } = props;
  const dispatch = useAppDispatch();
  const interviewParameters = useAppSelector(selectInterviewParameters);
  const totalPages = useAppSelector(selectTotalInterviewPages);
  const interviewIdToMatchingFragmentId = useAppSelector(
    selectInterviewIdToMatchingFragmentId
  );
  const placeholder = <span className="text-gray-400">--</span>;

  // Define table columns. Sortable by email, status, and started_at but not names.
  const coreColumns: ColumnDef<InterviewWithContactAndActivities>[] = [
    {
      accessorFn: (row: InterviewWithContactAndActivities) => {
        const {
          primaryDisplayName,
          secondaryDisplayName,
          tertiaryDisplayName,
        } = getContactDisplayNames(
          row.contact,
          row.contact.account ? row.contact.account.name : undefined
        );

        return (
          <div>
            <div>{primaryDisplayName}</div>
            {secondaryDisplayName && (
              <div className="text-gray-600">{secondaryDisplayName}</div>
            )}
            {tertiaryDisplayName && (
              <div className="text-gray-600">{tertiaryDisplayName}</div>
            )}
            <span className="text-gray-600">
              {formatDistanceToNow(new Date(row.started_at as string), {
                addSuffix: true,
              }).replace("about ", "")}
            </span>
            <div className="mt-2">
              <InfoTooltip
                id={row.id}
                content={computeTooltipForInterviewStatus(row.status)}
                place="bottom"
              >
                <Pill
                  size={SizesEnum.SMALL}
                  color={computePillColorForInterviewStatus(row.status)}
                  label={toHumanReadableString(
                    row.status === InterviewStatusEnum.IN_PROGRESS
                      ? "Partially completed"
                      : row.status
                  )}
                />
              </InfoTooltip>
            </div>
          </div>
        );
      },
      id: "email",
      header: () => <span>Contact</span>,
      enableSorting: false,
    },
    {
      accessorFn: (row: InterviewWithContactAndActivities) => (
        <div className="text-wrap">
          {row.insights.sentiment_score ? (
            <SentimentScore score={row.insights.sentiment_score} />
          ) : null}

          {row.insights.assessment_score ? (
            <AssessmentScore score={row.insights.assessment_score} />
          ) : null}

          {row.insights.summary ? (
            <div className="pl-5 text-sm mt-2">
              <InterviewSummary summary={row.insights.summary} />
            </div>
          ) : (
            placeholder
          )}

          <InterviewQuoteMatch interview={row} />
        </div>
      ),
      id: "summary",
      header: () => (
        <span>
          {isRecruitingProject ? "Assessment & Summary" : "Sentiment & Summary"}
        </span>
      ),
      enableSorting: false,
    },
  ];

  const columns = useMemo(() => coreColumns, []);

  const readOnlyColumn: Partial<ColumnDef<InterviewWithContactAndActivities>> =
    {
      cell: ({ getValue }) => {
        return (
          <div className="py-2 px-3 text-left text-sm">
            {getValue() as string}
          </div>
        );
      },
    };

  const table = useReactTable({
    data: interviews,
    columns,
    defaultColumn: readOnlyColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const renderTableBodyRows = () => {
    if (props.isLoading) {
      return <TableSkeleton />;
    }

    // Empty state.
    if (!interviews.length) {
      const defaultEmptyText =
        "When interviews are completed, AI-generated summaries and transcripts will appear here.";
      const searchEmptyText = "No matching interview transcripts found.";

      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm text-gray-700"
            colSpan={5}
          >
            {interviewParameters?.search_text
              ? searchEmptyText
              : defaultEmptyText}
          </td>
        </tr>
      );
    }

    return table.getRowModel().rows.map((row) => {
      return (
        <tr
          key={row.id}
          className={onInterviewClick && "hover:bg-blue-50 cursor-pointer"}
          onClick={() => {
            if (onInterviewClick) {
              const interviewId = row.original.id as string;
              const matchingTranscriptFragmentId =
                interviewIdToMatchingFragmentId[interviewId];
              onInterviewClick(interviewId, matchingTranscriptFragmentId);
            }
          }}
        >
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                key={cell.id}
                className="whitespace-nowrap text-xs text-gray-900 align-text-top text-left"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col flex-grow overflow-auto">
      {/* Container for the table that allows the body to scroll */}
      <div className="flex flex-col mx-auto mt-1 overflow-auto rounded-t-md border-t min-w-full">
        <table className="min-w-full border-x">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="bg-gray-100 sticky top-0 py-2 px-3 text-left text-sm font-semibold text-gray-900 h-11"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "flex items-center cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort()
                          ? getSortingChevron(
                              header.column.getIsSorted() as string
                            )
                          : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 overflow-y-auto">
            {renderTableBodyRows()}
          </tbody>
        </table>
      </div>
      <div className="bg-gray-100 border-gray-200 text-sm px-3 py-2 mb-4 rounded-b-md border min-h-11 flex">
        <PaginationControls
          page={interviewParameters?.page || 1}
          totalPages={totalPages || 1}
          onClickNextPage={() => {
            if (interviewParameters) {
              dispatch(
                fetchNonEmptyInterviewsForProject({
                  ...interviewParameters,
                  page: interviewParameters.page + 1,
                })
              );
            }
          }}
          onClickPreviousPage={() => {
            if (interviewParameters) {
              dispatch(
                fetchNonEmptyInterviewsForProject({
                  ...interviewParameters,
                  page: interviewParameters.page - 1,
                })
              );
            }
          }}
        />
      </div>
    </div>
  );
};

function computePillColorForInterviewStatus(
  status: InterviewStatusEnum
): PillColorsEnum {
  switch (status) {
    case InterviewStatusEnum.PENDING:
      return PillColorsEnum.YELLOW;
    case InterviewStatusEnum.IN_PROGRESS:
      return PillColorsEnum.BLUE;
    case InterviewStatusEnum.COMPLETED:
      return PillColorsEnum.GREEN;
    case InterviewStatusEnum.CANCELLED:
      return PillColorsEnum.RED;
  }
}

function computeTooltipForInterviewStatus(status: InterviewStatusEnum): string {
  switch (status) {
    case InterviewStatusEnum.PENDING:
      return "Interview has not been started yet";
    case InterviewStatusEnum.IN_PROGRESS:
      return "Some but not all interview questions have been answered";
    case InterviewStatusEnum.COMPLETED:
      return "All interview questions were answered";
    case InterviewStatusEnum.CANCELLED:
      return "Interview was cancelled before the participant answered any questions";
  }
}

function getSortingChevron(sortDirection?: string) {
  switch (sortDirection) {
    case "asc":
      return (
        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-200 h-fit">
          <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
        </span>
      );
    case "desc":
      return (
        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-200 h-fit">
          <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
        </span>
      );
    default:
      return <div className="ml-2 h-4 w-4" aria-hidden="true"></div>; // Placeholder with the same size as the chevrons.
  }
}
