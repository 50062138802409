import { CustomField } from "../../index.ts";
import { InterviewQuestion } from "../client/client.ts";
import { Tables, TablesInsert, TablesUpdate } from "../db/supabase.ts";
import { ComplexAnswerPublicAPI } from "./complexAnswer.ts";
import { BasicContact, ContactDB } from "./contact.ts";
import { TranscriptFragmentPublicAPI } from "./transcriptFragment.ts";

export enum InterviewStatusEnum {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export type InterviewCallMetadata = {
  id: string;
  call_id: string;
  agent_id: string;
  start_time: string;
  end_time: string;
  twilio_recording_id: string | undefined;
  recording_url: string | undefined;
};

export type InterviewMetadata = {
  feedback: InterviewFeedback | null;
  calls: InterviewCallMetadata[];
};

export type InterviewDB = Tables<"interview">;
export type InterviewInsert = Omit<TablesInsert<"interview">, "questions"> & {
  questions?: InterviewQuestion[];
};
export type InterviewUpdate = Omit<TablesUpdate<"interview">, "questions"> & {
  questions?: InterviewQuestion[];
};
export type Interview = Omit<
  Tables<"interview">,
  "questions" | "status" | "reward" | "insights" | "metadata"
> & {
  questions: InterviewQuestion[];
  status: InterviewStatusEnum;
  reward: InterviewReward | null;
  insights: InterviewInsights;
  metadata: InterviewMetadata | null;
};

export interface InterviewLoaded extends Interview {
  custom_fields: CustomField[];
}

type InterviewReward = {
  ext_id: string;
  type: string;
  value_denomination: number;
  value_currency_code: string;
  reward_link: string;
  sent_at: string;
};

export type InterviewWithBasicContact = Omit<Interview, "contact_id"> & {
  contact: BasicContact;
};

export type InterviewInsights = {
  summary: string | null;
  sentiment_score: number | null;
  assessment_score: number | null;
  key_positive_quotes: string[] | null;
  key_negative_quotes: string[] | null;
};

export enum InterviewFeedbackRatingsEnum {
  GREAT = "Great",
  REPETITIVE = "Repetitive",
  MISSING_FOCUS_AREAS = "Missing focus areas",
  OTHER = "Other feedback",
}

export type InterviewFeedback = {
  rating: InterviewFeedbackRatingsEnum;
  text: string | null;
};

type InterviewInsightsPublicAPI = {
  summary: string | null;
  sentiment_score: number | null;
  assessment_score: number | null;
  key_positive_quotes: string[] | null;
  key_negative_quotes: string[] | null;
};

export type InterviewPublicAPI = {
  id: string;
  project_id: string;
  status: InterviewStatusEnum;
  contact: BasicContact;
  started_at: string;
  completed_at: string | null;
  transcript_fragments: TranscriptFragmentPublicAPI[];
  survey_answers: ComplexAnswerPublicAPI[];
  insights: InterviewInsights | null;
  calls: InterviewPublicAPICall[];
};

export type InterviewPublicAPICall = {
  id: string;
  recording_url: string;
};

export const EMPTY_INTERVIEW_INSIGHTS: InterviewInsights = {
  summary: null,
  sentiment_score: null,
  assessment_score: null,
  key_positive_quotes: null,
  key_negative_quotes: null,
};

export const mapInterviewDbToInterview = (interviewDb: InterviewDB) => {
  const questions = (interviewDb.questions || []) as InterviewQuestion[];

  const status = interviewDb.status as InterviewStatusEnum;
  const reward = interviewDb.reward as InterviewReward;
  const insights: InterviewInsights = {
    ...EMPTY_INTERVIEW_INSIGHTS,
    ...(typeof interviewDb.insights === "object" ? interviewDb.insights : {}),
  };

  const interview: Interview = {
    ...interviewDb,
    questions: questions,
    status: status,
    reward: reward,
    insights: insights,
    metadata: (interviewDb.metadata as InterviewMetadata) || null,
  };

  return interview;
};

export const mapInterviewDbToInterviewLoaded = (
  interviewDb: InterviewDB,
  customFields: CustomField[]
) => {
  const interview = mapInterviewDbToInterview(interviewDb);
  const interviewLoaded: InterviewLoaded = {
    ...interview,
    custom_fields: customFields,
  };

  return interviewLoaded;
};

export const mapInterviewAndContactToInterviewWithContact = (
  interview: Interview,
  contact: ContactDB
): InterviewWithBasicContact => {
  return {
    ...interview,
    contact: {
      email: contact.email,
      phone_number: contact.phone_number,
      first_name: contact.first_name || null,
      last_name: contact.last_name || null,
    },
  } as InterviewWithBasicContact;
};

export const mapInterviewInsightsToPublicAPIInsights = (
  insights: InterviewInsights
) => {
  return {
    summary: insights.summary,
    sentiment_score: insights.sentiment_score,
    assessment_score: insights.assessment_score,
    key_positive_quotes: insights.key_positive_quotes,
    key_negative_quotes: insights.key_negative_quotes,
  } as InterviewInsightsPublicAPI;
};
